import { LocalStorageKey, getLocalStorage } from "@util/storage";
import { initialState, update } from "./reducers/user";
import { dispatch } from ".";
import { api } from "@util/api";

export const preloadedState = () => {
  const user = getLocalStorage(LocalStorageKey.User) || initialState;
  dispatch(update(user));
  api
    .get("/api/v1/auth/me")
    .then((res) => res.data)
    .then((res) => {
      if (res.status) {
        dispatch(update(res.data));
      }
    });
};
