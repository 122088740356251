import { ThemeConfig } from "antd";
import { colors } from "../colors";

export const antdThemeConfig: ThemeConfig = {
  token: {
    colorPrimary: colors.primary,
  },
  components: {
    Button: {
      defaultColor: colors.primary,
      defaultBorderColor: colors.primary,
      colorPrimary: colors.primary,
      colorPrimaryHover: colors.primary,
      dangerColor: colors.errorMain,
      colorError: colors.errorBackground,
    },
    Input: {
      colorPrimary: colors.defaultGrey,
      activeShadow: colors.defaultGrey,
      activeBorderColor: colors.defaultGrey,
      colorPrimaryHover: colors.defaultGrey,
    },
  },
};
