export const colors = {
  primary: "#EB8334",
  primaryBackground: "#FFF3EA",
  secondary: "#6064C3",
  secondaryBackground: "#F9F0FF",
  accentDasar: "#06CDCD",
  accentMadya: "#2D71CC",
  accentUtama: "#82108C",
  contentWhite: "#F9F9F9",
  contentBlack100: "#0F1020",
  contentBlack80: "#2E304C",
  contentBlack60: "#5E5F6F",
  contentBlack40: "#9E9EAC",
  contentBlack20: "#C1C1CB",
  defaultGrey: "#DCDCDC",
  elevation: "#F3F3F3",
  warningMain: "#CCC408",
  successMain: "#548C2F",
  successBackground: "#EBF4E5",
  errorMain: "#C5283D",
  errorBackground: "#FED5D5",
  outlinePrimary: "#828282",
  outlineSecondary: "#DCDCDC",
  successAlternative: "#c7e3b5",
};
