"use client";
import store from "@store/index";
import { preloadedState } from "@store/preloadedState";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import StyledComponentsRegistry from "@util/antd/AntdRegistry";
import { antdThemeConfig } from "@util/antd/config";
import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { Provider } from "react-redux";

const queryClient = new QueryClient();

export const AppProvider = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  useEffect(() => {
    preloadedState();
  }, []);

  return (
    <Provider store={store}>
      <ConfigProvider theme={antdThemeConfig}>
        <QueryClientProvider client={queryClient}>
          <StyledComponentsRegistry>{children}</StyledComponentsRegistry>
        </QueryClientProvider>
      </ConfigProvider>
    </Provider>
  );
};
